.login-outer-container {
  position : absolute;
  display: table;
  width: 100%; /* This could be ANY width */
  height: 100%; /* This could be ANY height */

  .login-background-video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;

  }
  
  .login-alert-marquee {
    top:0;
    left:0;
    right:0;
    position: absolute;
    .login-alert-item {
      top:0;
      position: absolute;
    }
    marquee {
      top:0;
      left:80px;
      right:0;
    }
    marquee>p {
      font-size: 14px;
      font-weight: bold;
    }


    &.alert {
      height: 40px;
      line-height:40px;
      padding:5px 15px;
    }
  }

  .login-inner-container {
    display: table-cell;
    vertical-align: middle;

    .row {

      @media (min-width: 992px)  { /*sm */
        display: table;
        width: 100%;
      }

      .login-left-area {
        margin-top: 30px;

        @media (min-width: 992px)  { /*sm */
          display: table-cell;
          vertical-align: middle;
          float: none;
        }
        @media (max-width: 991px){
          text-align: center;
        }
      }
      .login-right-area {
        @media (min-width: 992px)  { /*sm */
          display: table-cell;
          vertical-align: middle;
          float: none;
        }
        @media (max-width: 991px){
          text-align: center;
          margin-top: 30px;

        }
        .login-right-wrap {
          max-width: 245px;
          margin-left: 10%;
          @media (max-width: 991px){
            margin: 0 auto;
            max-width: none;
          }
          .login-right-content {
            margin-top: 20px;
          }
        }

        .form-horizontal .form-group{ margin-left: 0; margin-right: 0;}
        .input-group {
          .input-group-addon {
            background: none;
            border:0;
            color: #fff
          }
          .form-control{
            background: none;
            border:0;
            border-bottom: 1px #fff solid;
            border-radius: 0
          }
        }


        label {
          color: #fff;
        }

        input {
            border: 0;
            border-bottom: 1px solid white;
            box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
            border-radius: 0;
            outline: 0;
            background: transparent;
            color: #fff;
        }

        button {
          border-radius: 0;
        }
     }
    }
  }
}